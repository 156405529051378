<mat-form-field appearance="outline" class="full-width roam-field white-bg">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    [value]="currentValue"
    [placeholder]="placeholder"
    #roamSelect
    class="roam-menu"
    [panelClass]="'select-narrow'"
    [hideSingleSelectionIndicator]="true"
    (selectionChange)="onValueChange($event)">
    <mat-option *ngFor="let item of times" [value]="item.value">
      <p class="roam-text">{{ item.label }}</p>
    </mat-option>
  </mat-select>
  <button matSuffix mat-icon-button type="button" (click)="closeSelect()">
    <span [inlineSVG]="icon.clock.src"></span>
  </button>
</mat-form-field>
