import { Component, Input, OnInit, ViewChild, forwardRef } from "@angular/core";
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { InlineSVGModule } from "ng-inline-svg-2";
import { CommonModule } from "@angular/common";
import { ITime, ITimeNew } from "@app/shared/interfaces/time.interface";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatOptionModule,
    InlineSVGModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  selector: "app-roam-time",
  templateUrl: "./roam-time.component.html",
  styleUrls: ["./roam-time.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamTimeComponent),
      multi: true,
    },
  ],
})
export class RoamTimeComponent implements OnInit, ControlValueAccessor {
  @Input() public placeholder!: string;
  @Input() public minSeparator: number = 60;

  @ViewChild(MatSelect) roamSelect!: MatSelect;

  public times: ITimeNew[] = [];
  public icon = iconLib;
  public currentValue: string | null = null;

  onChange: (value: any) => void = () => {};
  onTouch: () => void = () => {};

  set value(val: string | null) {
    if (this.currentValue !== val) {
      this.currentValue = val;
      this.onChange(val);
      this.onTouch();
    }
  }

  writeValue(value: any): void {
    if (value) {
      const matchedTime = this.times.find(time => time.value === value)?.value;
      this.currentValue = matchedTime || null;
    } else {
      this.currentValue = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onValueChange(event: MatSelectChange): void {
    this.value = event.value;
  }

  ngOnInit(): void {
    this.times = this.createTimeArray();
  }

  private createTimeArray(): ITimeNew[] {
    const times: { value: string; label: string }[] = [];
    const interval = this.minSeparator > 0 ? this.minSeparator : 60;

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        const period = hour < 12 ? "AM" : "PM";
        const formattedHour = hour % 12 || 12;
        const formattedMinute = minute;

        // Format waktu untuk value dan label
        const formattedValue = `${hour.toString().padStart(2, "0")}:${formattedMinute
          .toString()
          .padStart(2, "0")}:00`;

        const formattedLabel = `${formattedHour
          .toString()
          .padStart(2, "0")}:${formattedMinute
          .toString()
          .padStart(2, "0")} ${period}`;

        // Tambahkan ke array times
        times.push({
          value: formattedValue,
          label: formattedLabel,
        });
      }
    }

    return times;
  }

  closeSelect(): void {
    this.roamSelect.close();
  }
}
